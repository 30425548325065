import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { addTrack } from './sharedActions';

export interface ContentState {
    remoteStream: MediaStream;
    remoteStreamID: string;
    remoteStreamTrackLength: number;
    remoteTracks: MediaStreamTrack[];
    subscribeError: boolean;
    muted: boolean;
    volume: number;
}

export const initialState: ContentState = {
    remoteStream: null,
    remoteStreamID: '',
    remoteStreamTrackLength: 0,
    remoteTracks: [],
    subscribeError: false,
    muted: false,
    volume: 50,
};

const contentSlice = createSlice({
    name: 'content',
    initialState,
    reducers: {
        clearTracks(state) {
            state.remoteTracks = [];
        },
        changeVolume(state, action: PayloadAction<number>) {
            state.muted = action.payload === 0;
            state.volume = action.payload;
        },
        toggleContentMuted(state, action: PayloadAction<boolean>) {
            const nextMuted = typeof action.payload === 'undefined' ? !state.muted : action.payload;
            state.muted = nextMuted;

            if (!nextMuted && state.volume === 0) {
                state.volume = 50;
            }
        },
        setSubscribeError(state, action: PayloadAction<boolean>) {
            state.subscribeError = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(addTrack, (state, action) => {
            const track = action.payload;
            state.remoteTracks.push(track);
            state.subscribeError = false;
        });
    },
});

export const { clearTracks, changeVolume, toggleContentMuted, setSubscribeError } =
    contentSlice.actions;

export * from './sharedActions';

export default contentSlice.reducer;
